<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t('oaSystem.jexlType')">
              <a-select style='width: 190px' v-model='queryParam.type'>
                <a-select-option v-for='item in jexlTypes' :key='item'>
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
              <a-button type='primary' @click='addJexl' icon='plus' style='margin-left: 8px'>{{ $t('add') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-modal v-model='editVisible' title='新增Jexl参数' @ok='handleOk' width='800px'>
        <a-form :form='addForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
          <a-form-item :label="$t('oaSystem.jexlType')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.jexlType')" v-model='addForm.type'></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.jexlExpression')">
            <a-textarea v-model='addForm.expression'></a-textarea>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model='testVisible' title='测试Jexl参数' width='800px' @ok='handleTest' okText='测试'>
        <a-form :form='testForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
          <a-form-item :label="$t('oaSystem.jexlExpression')">
            <a-textarea disabled='disabled' :placeholder="$t('enter') + ' ' + $t('oaSystem.jexlExpression')"
                        v-model='testForm.expression'></a-textarea>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.jexlParams')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.jexlParams')"
                     v-model='testForm.jexlParams'></a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='false'
        :loading='loading'
        bordered
        @change='handleTableChange'>
        <span slot='action' slot-scope='text, record'>
            <a @click='testJexl(record)'>{{ $t('oaSystem.test') }}</a>
        </span>
      </a-table>
      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { addJexl, getAllType, getJexlById, getJexlList, testJexlExpression } from '@/webpublicapi/oaJexl'
import moment from 'moment/moment'

const I18N_KEY = {
  OASYSTEM: 'oaSystem.'
}
export default {
  name: 'oaJexl',
  components: {},
  data() {
    return {
      editVisible: false,
      testVisible: false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      addForm: {
        type: undefined,
        expression: undefined,
      },
      testForm: {
        expression: undefined,
        jexlParams: undefined
      },
      total: 0,
      dataSource: [],
      jexlId: undefined,
      jexlTypes: [],
      // 查询条件
      queryParam: {
        'type': ''
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: 'id',
          align: 'left',
          dataIndex: 'id'
        },
        {
          title: this.$t('oaSystem.jexlType'),
          align: 'left',
          dataIndex: 'type'
        },
        {
          title: this.$t('oaSystem.jexlExpression'),
          align: 'left',
          ellipsis: true,
          dataIndex: 'expression'
        },
        {
          title: this.$t('oaSystem.createdTime'),
          align: 'left',
          dataIndex: 'createdTime'
        },
        {
          title: this.$t('oaSystem.createdBy'),
          align: 'left',
          dataIndex: 'createdBy'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created() {
    this.loadData(1)
  },
  methods: {
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getJexlList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
          this.dataSource.forEach(item => {
            item.createdTime = this.transformTime(item.createdTime)
            item.updatedTime = this.transformTime(item.updatedTime)
          })
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
      getAllType().then(res => {
        if (res.code === 1000) {
          this.jexlTypes = res.data
        } else {
          this.jexlTypes = []
          this.$message.error(res.msg)
        }
      })
    },
    getQueryParams() {
      const data = {
        ...this.queryParam
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.type = ''
      this.loadData()
    },
    addJexl() {
      this.editVisible = true
    },
    handleOk() {
      addJexl(this.addForm).then(res => {
        if (res.code === 1000) {
          this.$message.success(this.$t('success'))
          this.addForm = []
          this.editVisible = false
          this.loadData(1)
        } else {
          this.addForm = []
          this.$message.error(res.msg)
        }
      })
    },
    handleTest() {
      testJexlExpression(this.jexlId, this.testForm.jexlParams).then(res => {
        if (res.code === 1000) {
          this.$message.success(res.data)
        } else {
          this.$message.error(res.msg)
        }
      })

    },
    testJexl(record) {
      this.jexlId = record.id
      this.testVisible = true
      getJexlById(record.id).then(res => {
        this.testForm = res.data
      })
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
