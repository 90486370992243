import { axios } from '@/utils/request'

// 应用管理
export function getOtpReportList (parameter = {}) {
  return axios({
    url: '/otpCountReport/list',
    method: 'post',
    data: parameter
  })
}


