<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('oaSystem.workplaceName')">
              <a-select style="width: 190px" v-model="queryParam.workplaceName">
                <a-select-option v-for="item in oaWorkplaceNames" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="addWorkplace" icon="plus" style="margin-left: 8px">{{ $t('add') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-modal v-model="visdialog" title="新建职场" @ok="handleOk" width="800px">
        <a-form :form="editForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item :label="$t('oaSystem.personInCharge')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.personInCharge')" v-model="editForm.personInCharge"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.workplaceName')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.workplaceName')" v-model="editForm.workplaceName"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.workplacePositon')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.workplacePositon')" v-model="editForm.workplacePositon"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.workplaceArea')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.workplaceArea')" v-model="editForm.workplaceArea"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.officeCubicle')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.officeCubicle')" v-model="editForm.officeCubicle"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.departmentName')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.departmentName')" v-model="editForm.departmentName"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.workplaceCashPledge')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.workplaceCashPledge')" v-model="editForm.workplaceCashPledge"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.decorationCost')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.decorationCost')" v-model="editForm.decorationCost"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.rentMonthly')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.rentMonthly')" v-model="editForm.rentMonthly"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.propertyFee')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.propertyFee')" v-model="editForm.propertyFee"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.networkExpenseMonthly')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.networkExpenseMonthly')" v-model="editForm.networkExpenseMonthly"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.chargeWaterMonthly')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.chargeWaterMonthly')" v-model="editForm.chargeWaterMonthly"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.powerFeeMonthly')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.powerFeeMonthly')" v-model="editForm.powerFeeMonthly"></a-input>
          </a-form-item>
          <a-form-item :label="$t('oaSystem.otherFee')">
            <a-input :placeholder="$t('enter') + ' ' + $t('oaSystem.otherFee')" v-model="editForm.otherFee"></a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <span slot="action" slot-scope="text, record">
            <a @click="editWorkplace(record)">{{ $t('edit') }}</a>
            <a-divider type="vertical"/>
            <a @click="deleteWorkplace(record)">{{ $t('delete') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {
  deleteOaWorkplace,
  editWorkplace,
  getAllOaWorkplace,
  getAllOaWorkplaceName,
  getOaWorkplaceById
} from '@/webpublicapi/oaWorkplace'
import moment from 'moment'

const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
};
export default {
  name: 'oaWorkplace',
  components: {
  },
  data () {
    return {
      visdialog:false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      editForm: {
        personInCharge: '',
        workplaceName: '',
        workplacePositon: '',
        workplaceArea: '',
        officeCubicle: '',
        departmentName: '',
        workplaceCashPledge: '',
        decorationCost: '',
        rentMonthly: '',
        propertyFee: '',
        networkExpenseMonthly: '',
        chargeWaterMonthly: '',
        powerFeeMonthly: '',
        otherFee: '',
      },
      total: 0,
      dataSource: [],
      oaWorkplaceNames: [],
      // 查询条件
      queryParam: {
        "workplaceName": "",
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t('oaSystem.personInCharge'),
          align: 'left',
          dataIndex: 'personInCharge'
        },
        {
          title: this.$t('oaSystem.workplaceName'),
          align: 'left',
          dataIndex: 'workplaceName'
        },
        {
          title: this.$t('oaSystem.workplacePositon'),
          align: 'left',
          dataIndex: 'workplacePositon'
        },
        {
          title: this.$t('oaSystem.workplaceArea'),
          align: 'left',
          dataIndex: 'workplaceArea'
        },
        {
          title: this.$t('oaSystem.officeCubicle'),
          align: 'left',
          dataIndex: 'officeCubicle'
        },
        {
          title: this.$t('oaSystem.departmentName'),
          align: 'left',
          dataIndex: 'departmentName'
        },
        {
          title: this.$t('oaSystem.workplaceCashPledge'),
          align: 'left',
          dataIndex: 'workplaceCashPledge'
        },
        {
          title: this.$t('oaSystem.decorationCost'),
          align: 'left',
          dataIndex: 'decorationCost'
        },
        {
          title: this.$t('oaSystem.rentMonthly'),
          align: 'left',
          dataIndex: 'rentMonthly',
        },
        {
          title: this.$t('oaSystem.propertyFee'),
          align: 'left',
          dataIndex: 'propertyFee',
        },
        {
          title: this.$t('oaSystem.networkExpenseMonthly'),
          align: 'left',
          dataIndex: 'networkExpenseMonthly',
        },
        {
          title: this.$t('oaSystem.chargeWaterMonthly'),
          align: 'left',
          dataIndex: 'chargeWaterMonthly',
        },
        {
          title: this.$t('oaSystem.powerFeeMonthly'),
          align: 'left',
          dataIndex: 'powerFeeMonthly',
        },
        {
          title: this.$t('oaSystem.otherFee'),
          align: 'left',
          dataIndex: 'otherFee',
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleOk() {
      editWorkplace(this.editForm).then(res => {
        if(res.code===1000){
          this.$message.success(this.$t('success'))
          this.editForm = {
            personInCharge: '',
            workplaceName: '',
            workplacePositon: '',
            workplaceArea: '',
            officeCubicle: '',
            departmentName: '',
            workplaceCashPledge: '',
            decorationCost: '',
            rentMonthly: '',
            propertyFee: '',
            networkExpenseMonthly: '',
            chargeWaterMonthly: '',
            powerFeeMonthly: '',
            otherFee: '',
          }
          this.visdialog = false
          this.loadData(1)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    searchQuery () {
      this.loadData(1)
    },
    addWorkplace() {
      this.visdialog=true
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getAllOaWorkplace(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
      getAllOaWorkplaceName().then(res => {
        if(res.code===1000){
          this.oaWorkplaceNames = res.data
        }else{
          this.oaWorkplaceNames = []
          this.$message.error(res.msg)
        }
      })
    },
    getQueryParams () {
      const data = {
        ...this.queryParam,
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.workplaceName = ''
      this.loadData();
    },
    editWorkplace (row) {
      getOaWorkplaceById(row.id).then(res => {
        this.visdialog=true
        if(res.code===1000){
          this.editForm = res.data
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    deleteWorkplace(row) {
      deleteOaWorkplace(row.id).then(res => {
        if(res.code===1000){
          this.$message.success(this.$t('success'))
          this.loadData();
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
