<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'createdTime')">
              <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="4">
            <a-form-item label="盘号" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-input placeholder="请输入盘号" v-model="queryParam.appSsid"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="5">
            <a-form-item label="渠道" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-input placeholder="请输入渠道" v-model="queryParam.channel"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
      </a-table>

      <div class="table-page-wrapper">
        <a-pagination size="small" v-model="ipagination.pageNum" :pageSizeOptions="ipagination.pageSizeOptions"
                      :pageSize="ipagination.pageSize"
                      :total="ipagination.total"
                      :show-total="total => `共 ${total} 条`"
                      show-size-changer show-quick-jumper @change="handlePageChange" @showSizeChange="handlePageChange"/>
      </div>
    </div>
  </div>
</template>
<script>
import {filterObj} from "@/utils/util";
import moment from "moment";
import { getOtpReportList } from '@/webpublicapi/otpResport'
const I18N_KEY = 'custServiceWorkOrder.';

export default {
  name: 'WorkOrderDayReport',
  data () {
    return {
      I18N_KEY,
      loading: false,
      dataSource: [],
      fieldName: 'keyCnText',
      bordered: true,
      dateLabel: '',
      dateLabels: [],
      queryParam: {
        "createdTime": undefined,
        "channel": "",
        "appSsid": ""
      },
      // 分页参数
      ipagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: '盘号',
          align: 'left',
          width: '100px',
          dataIndex: 'appSsid'
        },
        {
          title: '渠道',
          align: 'left',
          width: '100px',
          dataIndex: 'channel'
        },
        {
          title: '登录手机号接收成功数',
          align: 'left',
          width: '100px',
          dataIndex: 'successLoginCount'
        },
        {
          title: '提交手机号总数',
          align: 'left',
          width: '100px',
          dataIndex: 'channelTotalCount'
        },
        {
          title: '登录手机号去重接收成功数',
          align: 'left',
          width: '100px',
          dataIndex: 'distinctSuccessLoginCount'
        },
        {
          title: '提交手机号去重总数',
          align: 'left',
          width: '100px',
          dataIndex: 'distinctChannelTotalCount'
        },
        {
          title: '发送otp成功数',
          align: 'left',
          width: '100px',
          dataIndex: 'successOtpSendCount'
        },
        {
          title: '发送otp总数',
          align: 'left',
          width: '100px',
          dataIndex: 'totalOtpSendCount'
        },
        {
          title: '发送otp去重成功数',
          align: 'left',
          width: '100px',
          dataIndex: 'distinctSuccessOtpSendCount'
        },
        {
          title: '发送otp去重总数',
          align: 'left',
          width: '100px',
          dataIndex: 'distinctTotalOtpSendCount'
        },
        {
          title: '手机号登录成功率',
          align: 'left',
          width: '100px',
          dataIndex: 'channelLoginRate'
        },
        {
          title: '手机号去重登录成功率',
          align: 'left',
          width: '100px',
          dataIndex: 'distinctChannelLoginRate'
        },
        {
          title: 'otp发送成功率',
          align: 'left',
          width: '100px',
          dataIndex: 'otpSendRate'
        },
        {
          title: 'otp去重发送成功率',
          align: 'left',
          width: '100px',
          dataIndex: 'distinctOtpSendRate'
        },
        {
          title: '统计日期',
          align: 'left',
          width: '100px',
          dataIndex: 'statisticDate'
        }
      ]
    },
  },
  created() {
    // this.loadData();
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getOtpReportList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    handlePageChange(pageNum, pageSize) {
      this.ipagination.pageNum = pageNum
      this.ipagination.pageSize = pageSize
      this.loadData()
    },
    getQueryParams () {
      const { createdTime } = this.queryParam;
      const data = {
        // ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
        channel: this.queryParam.channel,
        appSsid: this.queryParam.appSsid

      }
      var param = Object.assign({}, data)
      param.pageNum = this.ipagination.pageNum
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },

    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.createdTime = undefined
    },
    contains(sources, target) {
      for(var item in sources) {
        if(sources[item] === target) {
          return true;
        }
      }
      return false;
    },
    getColor(index, preColor) {
      if(index === 31) {
        return "#00FFFF";
      }
      if(index === 1) {
        return '#00FF00';
      }
      if(this.contains(indexs2, index)) {
        return '#FFFF00';
      }
      if(this.contains(indexs1, index)) {
        return '#F4B084';
      }
      return preColor;
    },
    getFont(index, preFont) {
      if(index === 31 || index === 1 || this.contains(indexs2, index) || this.contains(indexs1, index)) {
        return "bolder";
      }
      return preFont;
    },
    customRow(record, index) {
      return {
        style: {
          'background-color':this.getColor(index, record.value1.bgColor),
          'font-weight':this.getFont(index, 'unset'),
        }
      }
    },
    createColumnHeader() {
      var headers = [];
      var first = {
        title: this.$t(I18N_KEY + 'item'),// 项目
        align: 'left',
        width: 400,
        ellipsis: true,
        fixed: 'left',
        dataIndex: this.fieldName
      };
      headers.push(first);
      for (var index1 in this.dateLabels) {
        var item = {
          title: this.dateLabels[index1],
          align: 'left',
          width: 100,
          dataIndex: 'value'+this.add(index1,1),
        };
        headers.push(item);
      }
      return headers;
    },
    add(num1,num2) {
      return Number(num1)+Number(num2);
    }
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'keyEnText';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'keyCnText';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>