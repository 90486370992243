import { axios } from '@/utils/request'

export function getAllType (parameter = {}) {
  return axios({
    url: '/jexl/selectAllType',
    method: 'get',
    data: parameter
  })
}

export function getJexlList (parameter = {}) {
  return axios({
    url: '/jexl/list',
    method: 'post',
    data: parameter
  })
}

export function addJexl (parameter = {}) {
  return axios({
    url: '/jexl/add',
    method: 'post',
    data: parameter
  })
}

export function getJexlById (parameter) {
  return axios({
    url: '/jexl/selectJextById?id=' + parameter,
    method: 'get'
  })
}

export function testJexlExpression (jexlId, param) {
  return axios({
    url: '/jexl/testJexlExpression?id=' + jexlId + "&param=" + param,
    method: 'post',
  })
}