import { axios } from '@/utils/request'

export function getAllOaWorkplace (parameter = {}) {
  return axios({
    url: '/oaWorkplace/list',
    method: 'post',
    data: parameter
  })
}

export function getAllOaWorkplaceName (parameter = {}) {
  return axios({
    url: '/oaWorkplace/selectAllWorkplaceName',
    method: 'get',
    data: parameter
  })
}

export function editWorkplace (parameter = {}) {
  return axios({
    url: '/oaWorkplace/edit',
    method: 'post',
    data: parameter
  })
}

export function getOaWorkplaceById (id) {
  return axios({
    url: '/oaWorkplace/selectWorkplaceById?id=' + id,
    method: 'get',
  })
}

export function deleteOaWorkplace (id) {
  return axios({
    url: '/oaWorkplace/delete?id=' + id,
    method: 'post',
  })
}