<template>
    <a-card :bordered="false">

        <div class="table-page-search-wrapper">
            <a-form layout="inline" @keyup.enter.native="searchQuery">
                <a-row :gutter="12">
                    <a-col :md="6" :sm="8" style="margin-right: 20px;">
                        <a-form-item :label="'盘号'" sty>
                            <a-select v-model="queryParam.appSsid">
                                <a-select-option v-for="item in appList" :key="item.appssid">
                                    {{ item.appName }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :md="10" :sm="8">
                        <a-form-item :label="'日期'">
                            <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="4" :sm="6">
                        <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                            <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form>

            <a-table ref="table" rowKey="id" size="middle" :columns="columns" :dataSource="dataSource"
                :pagination="ipagination" :loading="loading" bordered @change="handleTableChange">
            </a-table>

        </div>
    </a-card>
</template>

<script>
import moment from 'moment'
import { getApplist } from '@/api/order'
import { afGaidList } from '@/api/order';

export default {
    name: 'gaidInstallCountReport',
    data() {
        return {
            moment,
            // 分页参数dict
            ipagination: {
                current: 1,
                pageSize: 100,
                pageSizeOptions: ['100', '200', '300'],
                showTotal: (total, range) => {
                    return range[0] + '-' + range[1] + ' 共' + total + '条'
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
            dataSource: [],
            appList: [],
            // 查询条件
            queryParam: {
                "appSsid": '',
                // "createdTime": [moment().startOf('day').format('YYYY-MM-DD'),moment().startOf('day').add(1, 'month').format('YYYY-MM-DD')]
                "createdTime": [moment().startOf('day').add(-1, 'month').format('YYYY-MM-DD'),moment().startOf('day').format('YYYY-MM-DD')]
            },
            loading: false,
            // 表头
            columns: [
                {
                    title: '日期',
                    align: 'center',
                    dataIndex: 'afCreatedTime'
                },
                {
                    title: '主盘号',
                    align: 'center',
                    dataIndex: 'appSsid'
                },
                {
                    title: 'GAID',
                    align: 'center',
                    dataIndex: 'totalGaidCount'
                },
                {
                    title: 'GAID已注册',
                    align: 'center',
                    dataIndex: 'oldGaidCount'
                },
                {
                    title: '重复率',
                    align: 'center',
                    dataIndex: 'regGaidRate',
                },

            ]
        }
    },
    created() {
        getApplist().then(res => {
            this.appList = res.data || [];
        })
    },
    methods: {
        searchQuery() {
            this.loadData()
        },
        handleTableChange(pagination) {
            this.ipagination = pagination
            this.loadData()
        },
        loadData() {
            let createdTime = this.queryParam.createdTime
            let params = {
                ...this.queryParam,
                startDate: createdTime && this.transformTime(createdTime[0]),
                endDate: createdTime && this.transformTime(createdTime[1]),
            }
            params.pageNum = this.ipagination.current
            params.pageSize = this.ipagination.pageSize
            this.loading = true
            console.log(params)
            // return
            afGaidList(params).then((res) => {
                if (res.code === 1000) {
                    this.dataSource=res.data.rows
                  this.ipagination.total = res.data.total

                } else {
                    this.ipagination.total = 0
                    this.dataSource = []
                    this.$message.error(res.msg)
                }
                this.loading = false
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            })
        },
        transformTime(time) {
            return moment(time).format('YYYY-MM-DD')
        },
    }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>